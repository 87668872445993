import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MouseContext } from "../../context/mouseContext";
import { useTranslation } from "react-i18next";
import logo from "../../../images/logo/logo.png";

const Navbar = () => {
    const pathname = useLocation().pathname;
    const { cursorChangeHandler } = useContext(MouseContext);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {/* Header-section Start */}
            <div className="header-holder services-section text-center animate__animated animate__zoomIn">
                <div className="navigation">
                    {/* Логотип */}
                    <div className="navbar-logo-container">
                        <NavLink to="/">
                            <div className="navbar-logo-shape">
                                <span>MS</span>
                            </div>
                        </NavLink>
                    </div>


                    <ul className="nav">
                        <li
                            className={`list ${pathname === "/" ? "active" : ""}`}
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}
                        >
                            <NavLink to="/" className="active">
                                <span className="icon">
                                    <i className="fas fa-house-user"></i>
                                </span>
                                <div className="social__tooltip social__tooltip-bottom">
                                    {t("menu.home")} {/* Перевод для "Home" */}
                                </div>
                            </NavLink>
                        </li>
                        <li
                            className={`list ${pathname === "/my-courses" ? "active" : ""}`}
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}
                        >
                            <NavLink to="/my-courses">
                                <span className="icon">
                                    <i className="fas fa-graduation-cap"></i>
                                </span>
                                <div className="social__tooltip social__tooltip-bottom">
                                    {t("menu.blog")} {/* Перевод для "Courses" */}
                                </div>
                            </NavLink>
                        </li>
                        <li
                            className={`list ${
                                pathname === "/my-projects" ? "active" : ""
                            }`}
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}
                        >
                            <NavLink to="/my-projects">
                                <span className="icon">
                                    <i className="fas fa-laptop-code"></i>
                                </span>
                                <div className="social__tooltip social__tooltip-bottom">
                                    {t("menu.portfolio")} {/* Перевод для "Projects" */}
                                </div>
                            </NavLink>
                        </li>
                        <li
                            className={`list ${pathname === "/about" ? "active" : ""}`}
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}
                        >
                            <NavLink to="/about">
                                <span className="icon">
                                    <i className="fas fa-user-alt"></i>
                                </span>
                                <div className="social__tooltip social__tooltip-bottom">
                                    {t("menu.about")} {/* Перевод для "About" */}
                                </div>
                            </NavLink>
                        </li>
                        <li
                            className={`list ${pathname === "/contact" ? "active" : ""}`}
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}
                        >
                            <NavLink to="/contact">
                                <span className="icon">
                                    <i className="fas fa-phone-alt"></i>
                                </span>
                                <div className="social__tooltip social__tooltip-bottom">
                                    {t("menu.contact")} {/* Перевод для "Contact" */}
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Header-section End */}
        </React.Fragment>
    );
};

export default Navbar;
