import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import OwlCarousel from 'react-owl-carousel';
import FunFactsBox from './FunFactsBox';
import { MouseContext } from "../context/mouseContext";
import Simple from '../../images/profile/new-profile.png'
import { Helmet } from 'react-helmet';
import Feedback1 from "../../images/profile/feedback-rz.jpg";
import FeedbackEmpty from "../../images/profile/photo-feedback-empty.png";
import Feedback4 from "../../images/profile/photo-feedback2.jpg";
import Feedback2 from "../../images/profile/photo-feedback4.jpg";
import Feedback5 from "../../images/profile/photo-feedback5.jpg";
import Feedback3 from "../../images/profile/photo-feedback1.jpg";
import Feedback6 from "../../images/profile/photo-feedback6.jpg";
import Feedback7 from "../../images/profile/photo-feedback7.jpg";

const About = () => {

  const { cursorChangeHandler } = useContext(MouseContext);

  const [options] = useState({
    loop: true,
    margin: 20,
    dots: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  });

  const downloadPdf = () => {
    const pdfFileName = 'CV_Mykola Shchypailo.pdf';

    // Construct the path to the PDF file
    const pdfPath = process.env.PUBLIC_URL + '/pdfs/' + pdfFileName;

    // Fetch the PDF file
    fetch(pdfPath)
      .then((response) => response.blob())
      .then((blob) => {
        // Save the blob as a file using file-saver
        saveAs(blob, pdfFileName);
      })
      .catch((error) => console.error('Error fetching or saving the file:', error));
  };

  // Single Item Data
  const servicesData = [
    {
      id: 1,
      icon: 'fas fa-laptop-code',
      title: 'Web Development',
      description: 'Build dynamic and responsive web applications using React, HTML5, CSS3.',
    },
    {
      id: 2,
      icon: 'fas fa-database',
      title: 'Backend Development',
      description: 'Develop reliable APIs and backend systems with Spring Boot and Java.',
    },
    {
      id: 3,
      icon: 'fas fa-bug',
      title: 'Manual Testing',
      description: 'Validate APIs with Swagger, create test cases, and ensure bug-free solutions.',
    },
    {
      id: 4,
      icon: 'fas fa-tools',
      title: 'Automation Testing',
      description: 'Create automated test solutions with Appium, Selenium, and Rest Assured.',
    },
    {
      id: 5,
      icon: 'fas fa-shield-alt',
      title: 'Security Integration',
      description: 'Secure applications with Spring Security, JWT, and OAuth2 protocols.',
    },
    {
      id: 6,
      icon: 'fas fa-cloud',
      title: 'Cloud & CI/CD',
      description: 'Set up cloud-ready deployments with Docker, GitLab CI/CD, and AWS hosting.',
    },
    {
      id: 7,
      icon: 'fas fa-project-diagram',
      title: 'Microservices',
      description: 'Design microservice architectures with Kafka, Nginx, and distributed systems.',
    },
    {
      id: 8,
      icon: 'fas fa-mobile-alt',
      title: 'Mobile Automation',
      description: 'Automate testing for Android and iOS applications with Appium and Java.',
    },
  ];

  // Testimonials Data
  const testimonialsData = [
    {
      id: 1,
      image: Feedback1,
      name: 'Viktor',
      text: 'Интересно, полезно, кратко и практично. Хотелось бы больше отсылок на теорию и возможно больше вводных формата "что это, для чего, почему"',
      rating: 5,
    },
    {
      id: 2,
      image: FeedbackEmpty,
      name: 'Yuriy F.',
      text: 'Без затягувань, чітко по суті, послідовно і зрозуміло, як починати і що за чим запускати.',
      rating: 5,
    },
    {
      id: 3,
      image: FeedbackEmpty,
      name: 'Yevhenii T.',
      text: 'Годний курс - все по ділу і динамічно) <br/> було б круто ще покрити iOS частину.',
      rating: 5,
    },
    {
      id: 4,
      image: Feedback2,
      name: 'Amelia',
      text: 'Спасибо, жду продолжения! Видео довольно таки очень информативные',
      rating: 5,
    },
    {
      id: 5,
      image: Feedback4,
      name: 'Саша',
      text: 'Спасибо за подробные и информативные видео',
      rating: 5,
    },
    {
      id: 6,
      image: FeedbackEmpty,
      name: 'Ірина',
      text: 'Добре і чітко все описано. Вже маю досвід але узнале і нове для себе)',
      rating: 4,
    },
    {
      id: 7,
      image: Feedback5,
      name: 'Ann',
      text: 'Спасибо, хороший материал, интересные видео',
      rating: 4,
    },
    {
      id: 8,
      image: Feedback3,
      name: 'Denys N.',
      text: 'Якісний матеріал та цікавий проект, приємно слухати лекції, все чітко и швидко',
      rating: 5,
    },
    {
      id: 9,
      image: FeedbackEmpty,
      name: 'Serg',
      text: 'Good course',
      rating: 4,
    },
    {
      id: 10,
      image: Feedback6,
      name: 'Bbhavani',
      text: 'Thanks for helping me in understanding Spring. Please make more videos and share the subject. Thanks a lot dear.',
      rating: 5,
    },
    {
      id: 11,
      image: FeedbackEmpty,
      name: 'Dmytro',
      text: 'Курс классний, все докладно пояснено, матеріали доступні для розуміння.',
      rating: 4,
    },
    {
      id: 12,
      image: Feedback7,
      name: 'Alex',
      text: 'Good, thx:))',
      rating: 4,
    },
    {
      id: 13,
      image: FeedbackEmpty,
      name: 'Moskalenko N.',
      text: 'Материал подается последовательно и очень подробно.',
      rating: 4,
    },
    {
      id: 14,
      image: FeedbackEmpty,
      name: 'Sergiy K.',
      text: 'Great course for beginners!',
      rating: 4,
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        {/* SEO Page title tag */}
        <title>Mykola Shchypailo | About me</title>
        {/* Meta description */}
        <meta name="description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />;

        {/* Twitter card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://avstechnolabs.com/og-images/seo-banner.png" />
        <meta name="twitter:title" content="Mykola Shchypailo | Programming courses" />
        <meta name="twitter:creator" content="@mavi" />
        <meta name="twitter:site" content="@mavi" />
        <meta name="twitter:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />

        {/* Facebook card meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nick8787.github.io/m-shchypailo" />
        <meta property="og:title" content="Mykola Shchypailo | Programming courses"
        />
        <meta property="og:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
        />
        <meta property="og:image" content="https://avstechnolabs.com/og-images/seo-banner.png"
        />
      </Helmet>
      {/* About Section Start  */}
      <section id="about" className="section active">
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__fadeInDown">
          <div className="container">
            <div className="about-content">
              {/* About Title Start */}
              <div className="row ">
                <div className="col-12 ">
                  <div className="section-title animate__animated animate__fadeInDown">
                    <p className="common-desctiption">My Intro</p>
                    <h1 className="common-title">About <span>Me</span></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
              {/* About Title End  */}
              {/* About-Me  */}
              <div className="row single-section">
                <div className="col-lg-4 profile-photo animate__animated animate__fadeInLeft">
                  <div className="row">
                    <div className="col-12 d-block col-sm-none">
                      <img src={Simple} className="img-fluid main-img-mobile" alt="my picture"/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                  <div className="row personal-info animate__animated animate__fadeInRight">

                    <div className="col-12">
                      <h5 className="personal-title">who am <span>i ?</span></h5>
                      <h3 className="personal-title">I'm Mykola Shchypailo, <span>Software Engineer</span>, <span>AQA Engineer</span>, <span>Web
                        Developer</span> and <span>Backend developer</span></h3>
                      <p className="info">
                        As experienced web and backend developer, I bring expertise in
                        <b> Java</b>, <b>Spring Boot</b>, <b>React</b>, <b>CSS</b>, <b>HTML 5</b> and <b>JavaScript</b>. My skill set
                        includes
                        proficiency in cutting-edge technologies such as
                        <b> Appium</b>, <b> Maven</b>, <b> Rest
                        Assured</b>, <b> Node.js</b>, <b>Docker</b>, <b>Kafka</b>, and <b>PostgreSQL</b>.
                        <br/>I am dedicated to developing reliable, scalable, and efficient solutions that align with
                        client
                        objectives while maintaining a strong focus on performance and security. My goal is to deliver
                        high-quality results that drive innovation and exceed expectations.
                      </p>

                    </div>

                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        <h3 className="personal-infotitle">personal <span>information</span></h3>
                      </div>

                      <div className="col-lg-6">
                        <ul className="about-you ">
                          <li>
                            <span className="title">first name :</span>
                            <span className="value">Mykola</span>
                          </li>
                          <li>
                            <span className="title">last name :</span>
                            <span className="value">Shchypailo</span>
                          </li>
                          <li>
                            <span className="title">address :</span>
                            <span className="value">Kiev, Ukraine</span>
                          </li>
                          <li>
                            <span className="title">Age :</span>
                            <span className="value">28 years</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="about-you ">
                          <li>
                            <span className="title">E-mail :</span>
                            <span className="value">
                                <Link
                                    href="mailto:mykola.shchypailo@gmail.com"
                                    onMouseEnter={() => cursorChangeHandler("hovered")}
                                    onMouseLeave={() => cursorChangeHandler("")}
                                    style={{textTransform: "lowercase"}}
                                >
                                  mykola.shchypailo@gmail.com
                                </Link>
                              </span>
                          </li>
                          <li>
                            <span className="title">YouTube :</span>
                            <span className="value">
                              <a
                                  href="https://www.youtube.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}
                              >
                                @m-shchypailo
                              </a>
                              <span style={{textTransform: "lowercase"}}> (channel under development)</span>
                            </span>
                          </li>
                          <li>
                            <span className="title">Udemy :</span>
                            <span className="value">
                              <a
                                  href="https://www.udemy.com/user/mykola-shchypailo/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}
                                  style={{textTransform: "none"}}
                              >
                                Check out my courses on Udemy
                              </a>
                            </span>
                          </li>
                          <li>
                            <span className="title">Languages :</span>
                            <span className="value">English B2, Ukrainian native</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-12">
                        <div className="About-btn">
                          <button onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")} onClick={downloadPdf}
                                  className="clickbtn download-cv">Download CV <i className="fa fa-download"
                                                                                  aria-hidden="true"></i></button>
                          {/* Social media icons */}
                          <div className="col-lg-7 col-sm-6 col-12">
                            <ul className="list-unstyled social-icons">
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                                  <i className="fab fa-youtube"></i>
                                </a>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <a href="https://www.udemy.com/user/mykola-shchypailo/" target="_blank"
                                   rel="noopener noreferrer">
                                  <i className="fas fa-graduation-cap"></i>
                                </a>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <Link to="https://www.linkedin.com/" target="_blank"
                                      rel="noopener noreferrer">
                                  <i className="fab fa-linkedin"></i></Link>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <Link to="https://github.com/nick8787" target="_blank" rel="noopener noreferrer">
                                  <i className="fab fa-github-square"></i></Link>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <a href="https://www.instagram.com/nick_87.87/" target="_blank"
                                   rel="noopener noreferrer">
                                  <i className="fab fa-instagram-square"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* About-Me End  */}

              {/* Resume section Start  */}
              <div className="resume-section ">
                {/* Resume title */}
                <div className="row">
                  <div className="col-12">
                    <div className="section-title animate__animated animate__fadeInUp">
                      <p className="common-desctiption">Check out my Resume</p>
                      <h1 className="common-title ">my <span>Resume</span></h1>
                      <div className="animated-bar "></div>
                    </div>
                  </div>
                </div>
                {/* Resume title End */}
                {/* Resume Content  */}
                <div className="row">
                  {/* Education part */}
                  <div className="col-md-6 col-12 ">
                    <div className=" col-block education ">
                      <h3 className="about-subtitle">Education</h3>
                      <div className="resume-item"><span className="item-arrow"></span>
                        <h5 className="item-title">Software Development Program</h5>
                        <span className="item-details">Mate Academy, Remote / 2019 – 2020</span>
                        <p className="item-description">
                          Completed an intensive software development program focused on full-stack development,
                          testing, and best practices in software engineering.
                        </p>

                      </div>
                      <div className="resume-item"><span className="item-arrow"></span>
                        <h5 className="item-title">Bachelor of International Law</h5>
                        <span className="item-details">Kiev University of Law of the National Academy of Sciences of Ukraine, 2014 – 2019 </span>
                        <p className="item-description">
                          Studied international law with a focus on legal principles, global systems, and their
                          application in the international arena.
                        </p>

                      </div>
                    </div>
                  </div>
                  {/* Experience part */}
                  <div className="col-md-6 col-12 ">
                    <div className="col-block education">
                      <h3 className="about-subtitle">Experience</h3>

                      <div className="resume-item">
                        <span className="item-arrow"></span>
                        <h5 className="item-title">QA Automation Engineer</h5>
                        <span className="item-details">Extrawest (ICASA, Electriqua, Cariqa) / Dec 2023 – Present</span>
                        <p className="item-description">
                          Working on API and mobile test automation using Java, Rest Assured, Android Studio, and
                          Appium. Responsible for creating automation frameworks, optimizing CI/CD pipelines, and
                          maintaining project documentation.
                        </p>
                      </div>

                      <div className="resume-item">
                        <span className="item-arrow"></span>
                        <h5 className="item-title">Web/Backend Developer</h5>
                        <span className="item-details">Freelance / Feb 2023 – Present</span>
                        <p className="item-description">
                          Developing websites and backend systems, focusing on creating scalable and efficient solutions
                          for various projects.
                        </p>
                      </div>


                      <div className="resume-item">
                        <span className="item-arrow"></span>
                        <h5 className="item-title">QA Automation Engineer</h5>
                        <span className="item-details">Project under NDA (Binary options) / Nov 2022 – Dec 2023</span>
                        <p className="item-description">
                          Focused on automating UI and API tests using Java, Selenide, Rest Assured, and Postman. Also
                          worked on improving QA processes and conducting performance testing using Gatling.
                        </p>
                      </div>

                      <div className="resume-item">
                        <span className="item-arrow"></span>
                        <h5 className="item-title">General QA Engineer</h5>
                        <span className="item-details">EXIST.UA / Mar 2021 – Nov 2022</span>
                        <p className="item-description">
                          Specialized in manual testing and in automating UI tests using Java, Selenium, Maven, and
                          TestNG. Performed
                          performance testing with JMeter and ensured application security through OWASP ZAP.
                        </p>
                      </div>
                    </div>

                  </div>

                </div>
                {/* Resume ContentEnd  */}
              </div>
              {/* / Resume section End */}
              {/* Skill Bar Section  */}
              {/*<div className="skill-section">*/}
              {/*  <div className="row">*/}
              {/*    <div className="col-12 ">*/}
              {/*      <div className="section-title animate__animated animate__fadeInUp animate__delay-3s">*/}
              {/*        <p className="common-desctiption">My level of knowledge in some tools</p>*/}
              {/*        <h1 className="common-title">my <span>skills</span></h1>*/}
              {/*        <div className="animated-bar"></div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-lg-6 col-md-12 ">*/}
              {/*      <h3 className="about-subtitle">Design <span>Skills</span></h3>*/}
              {/*      <div className="skill-bars">*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Photoshop</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Photoshop">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Illustrator</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Illustrator">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Figma</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Figma">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Indesign</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Indesign">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Sketch</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Sketch">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-lg-6 col-md-12 ">*/}
              {/*      <h3 className="about-subtitle">Coding <span>Skills</span></h3>*/}
              {/*      <div className="skill-bars">*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>HTML</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line html">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>CSS</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line css">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>jQuery</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line jquery">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Python</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line python">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>MySQL</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line mysql">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* / Skill Bar Section  */}
              {/* service section   */}
              <div className="services-section text-center">
                <div className="row">
                  <div className="col-12">
                    <div
                        className="section-title animate__animated animate__fadeInUp animate__slower">
                      <p className="common-desctiption">What I offer</p>
                      <h1 className="common-title">My <span>Expertise</span></h1>
                      <div className="animated-bar"></div>
                    </div>
                    <p className="service-text">
                      I specialize in creating scalable <span className="highlight-skill">web applications</span>,
                      <span className="highlight-skill"> backend systems</span>, and <span className="highlight-skill">automation solutions</span>.
                      From modern <span className="highlight-skill">UI/UX design</span> to reliable <span
                        className="highlight-skill">backend architecture </span>
                      and automated testing, I bring expertise in <span className="highlight-skill">Java</span>,
                      <span className="highlight-skill"> Spring Boot</span>, <span
                        className="highlight-skill">React</span>,
                      and <span className="highlight-skill">JavaScript</span>. My skill set includes proficiency in
                      cutting-edge technologies
                      such as <span className="highlight-skill">Node.js</span>, <span
                        className="highlight-skill">Docker</span>,
                      <span className="highlight-skill"> Kafka</span>, and <span
                        className="highlight-skill">PostgreSQL</span>.
                    </p>
                  </div>
                </div>
                <div className="row animate__animated animate__zoomIn animate__slower">
                  {servicesData.map((service) => (
                      <div key={service.id}
                           className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower">
                        <div className="service-item">
                          <i className={service.icon}></i>
                          <h4><span className="service-line">{service.title}</span></h4>
                          <p>{service.description}</p>
                        </div>
                      </div>
                  ))}
                </div>
              </div>

              {/* / service section  */}
              {/* Testimonials  */}
              <div className="row">
                <div className="col-12 animate__animated animate__fadeInDown">
                  <div className="section-title">
                    <p className="common-desctiption">what my students say</p>
                    <h1 className="common-title">My <span>Feedback</span></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <OwlCarousel className="owl-theme" id="testimonial" {...options}>
                  {testimonialsData.map((testimonial) => (
                      <div key={testimonial.id} className="item">
                        <div className="testimonial-item">
                          <div className="quote">
                            <i className="fas fa-quote-left"></i>
                          </div>
                          <div className="testimonial-img">
                            <img src={testimonial.image} alt={`testimonial-${testimonial.id}`}/>
                          </div>
                          {/* Имя комментатора */}
                          <h5 className="highlight-skill">{testimonial.name}</h5>
                          {/* Текст комментария */}
                          <p dangerouslySetInnerHTML={{__html: testimonial.text}}/>
                          <div className="rating">
                            {Array.from({length: testimonial.rating}, (_, index) => (
                                <i key={index} className="fas fa-3x fa-star"></i>
                            ))}
                          </div>
                        </div>
                      </div>
                  ))}
                </OwlCarousel>
              </div>
              {/* End Testimonials section  */}
              {/* Fun Fact Section  */}
              <div className="funfacts-section">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title">
                      <p className="common-desctiption">Here are some stats</p>
                      <h1 className="common-title">Some <span>facts</span> about <span>me</span></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={5}/>
                      <p className="fun-text">years of <span>experience</span></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={1200}/>
                      <p className="fun-text">happy students</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={3}/>
                      <p className="fun-text">completed courses</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={15}/>
                      <p className="fun-text">Countries Reached</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Section End  */}
    </React.Fragment>
  )
}

export default About