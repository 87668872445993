import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { MouseContext } from "../context/mouseContext";
import Blog1 from '../../images/blog/spring-boot.png'
import Blog2 from '../../images/blog/mobile-app.png'
import Blog3 from '../../images/blog/api-rest.png'
import Blog4 from '../../images/blog/web-selenide.png'
import { Helmet } from 'react-helmet';


const blogData = [
  {
    id: 1,
    image: Blog1,
    date: '31 Aug, 2024',
    title: 'Spring Boot - Web Application Development [2024]',
    description: '<i>[Course is in process...]</i> <br />In this course you\'ll develop web applications from scratch using <span class="highlight-skill">Spring Boot</span>. ' +
        '<br/>This course is perfect for beginners and those who want to deepen their knowledge and skills in BE development. ' +
        '<br/>While the course is in development, <span class="highlight-skill">subscribe for FREE</span> and wait for updates.',
    rating: 4.9,
    link: 'https://www.udemy.com/course/spring-boot-java-project/',
  },
  {
    id: 2,
    image: Blog2,
    date: '28 Jun, 2024',
    title: 'Appium - Mobile App Automation, Java + Ci/CD on GitLab',
    description: 'This course covers Mobile Test Automation with <b>Appium</b> and <b>Java</b>.' +
        ' <br/>Learn to set up <span class="highlight-skill">Android Studio</span>, <span class="highlight-skill">Appium</span>, and Emulators, create a project from scratch. ' +
        'You\'ll work with <b>Java principles</b>, and frameworks like <b>JUnit</b> and <b>Selenium</b>. ' +
        '<br/>The course also includes <span class="highlight-skill">GitLab CI/CD</span>, <span class="highlight-skill">running tests on remote devices</span>, and <span class="highlight-skill">generating reports</span>.',
    rating: 4.7,
    link: 'https://www.udemy.com/course/appium-automation-mobile-app-java-cicd-gitlab/',
  },
  {
    id: 3,
    image: Blog3,
    date: '15 Jul, 2024',
    title: 'API Automation - Java, Rest Assured, Maven + Postman + Ci/CD',
    description: 'In this course you\'ll create <span class="highlight-skill">API framework from scratch</span> using <b>Java</b>. ' +
        '<br/>Learn manual testing with <span class="highlight-skill">Postman</span>, build a framework with <span class="highlight-skill">Rest Assured</span>, and handle JSON parsing, HTTP requests, and token management. ' +
        '<br/>Set up <span class="highlight-skill">CI/CD pipelines on GitLab</span> for automated and scheduled test execution with detailed reporting.',
    rating: 4.6,
    link: 'https://www.udemy.com/course/api-java-rest-assured-maven-postman-cicd',
  },
  {
    id: 4,
    image: Blog4,
    date: '20 Dec, 2024',
    title: 'Web Automation - Java, Selenide, Maven, TestNG + Ci/CD',
    description: '<i>[Course is in process...]</i>',
  }
];

const Courses = () => {
  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <React.Fragment>
      <Helmet>
        {/* SEO Page title tag */}
        <title>Mykola Shchypailo | My courses</title>
        {/* Meta description */}
        <meta name="description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."/>
        ;

        {/* Twitter card meta tags */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content="https://avstechnolabs.com/og-images/seo-banner.png"/>
        <meta name="twitter:title" content="Mykola Shchypailo | Programming courses" />
        <meta name="twitter:creator" content="@mavi" />
        <meta name="twitter:site" content="@mavi" />
        <meta name="twitter:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />

        {/* Facebook card meta tags */}
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://nick8787.github.io/m-shchypailo/my-courses"/>
        <meta property="og:title" content="Mykola Shchypailo | Programming courses"
        />
        <meta property="og:description"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
        />
        <meta property="og:image" content="https://avstechnolabs.com/og-images/seo-banner.png"
        />
      </Helmet>
      {/* Courses section Start  */}
      <section id="blog" className="section active">
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__fadeInLeft">
          <div className="container">
            {/* Courses-Section Title  */}
            <div className="blog-section text-center">
              <div className="row ">
                <div className="col-12">
                  <div className="section-title animate__animated animate__fadeInDown">
                    <p className="common-desctiption">Check Out My Courses</p>
                    <h1 className="common-title">My <span>Courses</span></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* Courses-Section Title End  */}
            {/* Courses-Content Box Start */}
            <div className="blog-section animate__animated animate__fadeInUp">
              <div className="row justify-content-center">
                {/* Single post */}
                {blogData.map((blog) => (
                    <div key={blog.id} className="col-lg-4 col-md-6 col-sm-12">
                      <div className="single-post">
                        {/* Изображение */}
                        <div className="ih-item square effect">
                          <Link
                              to={
                                blog.id === 1
                                    ? '/spring-boot'
                                    : blog.id === 2
                                        ? '/mobile-app'
                                        : blog.id === 3
                                            ? '/api-automation'
                                            : blog.id === 4
                                                ? '/web-automation'
                                                : '/'
                              }
                              onMouseEnter={() => cursorChangeHandler("hovered")}
                              onMouseLeave={() => cursorChangeHandler("")}
                          >
                            <div className="img">
                              <img src={blog.image} alt="img"/>
                            </div>
                          </Link>
                        </div>

                        {/* Тело поста */}
                        <div className="card-body post-content">
                          {/* Дата и рейтинг */}
                          <div className="content-date-rating">
                            <span className="content-date">{blog.date}</span>
                            {blog.rating && (
                                <span className="content-rating">
                  <span className="rating-label">Udemy Rating:</span>
                  <div className="rating-stars">
                    {Array.from({length: 5}).map((_, index) => {
                      const starType =
                          blog.rating >= 4.9 // Если рейтинг >= 4.9, закрашиваем все звезды
                              ? "full"
                              : blog.rating >= index + 1
                                  ? "full" // Полная звезда для рейтингов < 4.9
                                  : blog.rating > index
                                      ? "half" // Половина звезды
                                      : "empty"; // Пустая звезда
                      return <span key={index} className={`star ${starType}`}/>;
                    })}
                  </div>
                  <span className="rating-value">{blog.rating} / 5</span>
                </span>
                            )}
                          </div>

                          {/* Заголовок */}
                          <div className="content-title">
                            <h5>
                              <Link
                                  to={
                                    blog.id === 1
                                        ? '/spring-boot'
                                        : blog.id === 2
                                            ? '/mobile-app'
                                            : blog.id === 3
                                                ? '/api-automation'
                                                : blog.id === 4
                                                    ? '/web-automation'
                                                    : '/'
                                  }
                                  onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}
                              >
                                {blog.title}
                              </Link>
                            </h5>
                          </div>

                          {/* Описание */}
                          <div className="content-description">
                            <p dangerouslySetInnerHTML={{__html: blog.description}}/>
                          </div>

                          {/* Кнопка перехода на Udemy */}
                          {blog.link ? (
                              <div className="udemy-btn-container text-center mt-4">
                                <button
                                    onMouseEnter={() => cursorChangeHandler("hovered")}
                                    onMouseLeave={() => cursorChangeHandler("")}
                                    onClick={() => window.open(blog.link, "_blank")}
                                    className="clickbtn download-cv"
                                >
                                  Buy Course <i className="fa fa-external-link" aria-hidden="true"></i>
                                </button>
                              </div>
                          ) : (
                              <div className="udemy-btn-container text-center mt-4">
                                <button className="clickbtn download-cv disabled" disabled>
                                  Coming Soon <i className="fa fa-clock-o" aria-hidden="true"></i>
                                </button>
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
            {/* Courses-Content Box End */}
          </div>
        </div>
      </section>
      {/* Courses section End  */}
    </React.Fragment>
  )
}

export default Courses