import React, { useState } from "react";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Blog1 from "../../../images/blog/spring-boot.png";
import FunFactsBox from "../FunFactsBox";
import FeedbackEmpty from '../../../images/profile/photo-feedback-empty.png'
import Feedback5 from '../../../images/profile/photo-feedback5.jpg'
import Feedback4 from '../../../images/profile/photo-feedback7.jpg'
import Feedback3 from '../../../images/profile/photo-feedback6.jpg'
import Feedback2 from '../../../images/profile/photo-feedback4.jpg'
import Feedback1 from '../../../images/profile/photo-feedback1.jpg'

const SpringBoot = () => {

    const [options] = useState({
        loop: true,
        margin: 20,
        dots: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 3 },
        },
    });
    // Single Item Data
    const servicesData = [
        {
            id: 1,
            icon: 'fas fa-cubes',
            title: 'Spring Framework',
            description: 'Master the essentials of Spring Framework, including IoC, DI, and creating RESTful APIs for modern applications.',
        },
        {
            id: 2,
            icon: 'fas fa-database',
            title: 'Database Management',
            description: 'Learn to work with databases using Spring Data JPA, PostgreSQL, and Flyway for seamless migrations.',
        },
        {
            id: 3,
            icon: 'fas fa-lock',
            title: 'Spring Security',
            description: 'Secure your applications with Spring Security, including user authentication and role-based access control.',
        },
        {
            id: 4,
            icon: 'fas fa-stream',
            title: 'Kafka Integration',
            description: 'Understand Kafka for asynchronous processing, and learn to configure brokers, topics, producers, and consumers.',
        },
        {
            id: 5,
            icon: 'fas fa-box-open',
            title: 'Docker & Containers',
            description: 'Learn to containerize applications with Docker, create Docker images, and manage deployments via Docker Hub.',
        },
        {
            id: 6,
            icon: 'fas fa-network-wired',
            title: 'CORS & Nginx',
            description: 'Set up and manage CORS policies and use Nginx to optimize and secure backend services.',
        },
        {
            id: 7,
            icon: 'fas fa-key',
            title: 'SSL & Certificates',
            description: 'Configure SSL certificates to secure communication and enable HTTPS for your applications.',
        },
        {
            id: 8,
            icon: 'fas fa-tools',
            title: 'Testing & Debugging',
            description: 'Develop unit tests, debug applications, and ensure code reliability with industry-standard tools and practices.',
        },
    ];

    // Testimonials Data
    const testimonialsData = [
        {
            id: 1,
            image: Feedback1,
            name: 'Denys N.',
            text: 'Якісний матеріал та цікавий проект, приємно слухати лекції, все чітко и швидко',
            rating: 5,
        },
        {
            id: 2,
            image: FeedbackEmpty,
            name: 'Juris G.',
            text: 'Спасибо, пока все понятно',
            rating: 5,
        },
        {
            id: 3,
            image: Feedback2,
            name: 'Amelia',
            text: 'Спасибо, жду продолжения! Видео довольно таки очень информативные',
            rating: 5,
        },
        {
            id: 4,
            image: Feedback3,
            name: 'Bbhavani',
            text: 'Thanks for helping me in understanding Spring. Please make more videos and share the subject. Thanks a lot dear.',
            rating: 5,
        },
        {
            id: 5,
            image: FeedbackEmpty,
            name: 'Hans',
            text: 'Nice',
            rating: 5,
        },
        {
            id: 6,
            image: FeedbackEmpty,
            name: 'Иван',
            text: 'Отличный курс! Затронуто множество полезных тем, подробно разобраны все детали',
            rating: 5,
        },
        {
            id: 7,
            image: Feedback4,
            name: 'Alex',
            text: 'Good, thx:))',
            rating: 4,
        },
        {
            id: 8,
            image: Feedback5,
            name: 'Ann',
            text: 'Спасибо, хороший материал, интересные видео',
            rating: 4,
        },
        {
            id: 9,
            image: FeedbackEmpty,
            name: 'Brayo M.',
            text: 'Yes! great course I recommend to anyone',
            rating: 5,
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                {/* SEO Page title tag */}
                <title>Mykola Shchypailo | Spring Boot</title>
                {/* Meta description */}
                <meta name="description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />;

                {/* Twitter card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content="https://avstechnolabs.com/og-images/seo-banner.png" />
                <meta name="twitter:title" content="Mykola Shchypailo | Programming courses" />
                <meta name="twitter:creator" content="@mavi" />
                <meta name="twitter:site" content="@mavi" />
                <meta name="twitter:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />

                {/* Facebook card meta tags */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://nick8787.github.io/m-shchypailo" />
                <meta property="og:title" content="Mykola Shchypailo | Programming courses"
                />
                <meta property="og:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
                />
                <meta property="og:image" content="https://avstechnolabs.com/og-images/seo-banner.png"
                />
            </Helmet>
            {/* About Section Start  */}
            <section id="about" className="section active">
                <div className="homecolor-box"></div>
                <div className="common_bg animate__animated animate__fadeInDown">
                    <div className="container">
                        <div className="about-content">
                            {/* About Title Start */}
                            <div className="row ">
                                <div className="col-12 ">
                                    <div
                                        className="section-title animate__animated animate__fadeInDown">
                                        <p className="common-desctiption">My Course</p>
                                        <h1 className="common-title">Spring <span>Boot</span> -
                                            Backend <span>development</span></h1>
                                        <div className="animated-bar"></div>
                                    </div>
                                </div>
                            </div>
                            {/* About Title End  */}
                            {/* Изображение */}
                            <div
                                className="col-12 text-center animate__animated animate__fadeInUp"
                            >
                                <img
                                    src={Blog1}
                                    alt="Spring Boot"
                                    className="img-fluid custom-image"
                                    style={{
                                        maxWidth: "60%",
                                        height: "auto",
                                        marginBottom: "30px",
                                        boxShadow: "0 15px 30px rgba(0, 0, 0, 0.5)",
                                        borderRadius: "8px",
                                    }}
                                />
                                <style>
                                    {`
      @media (max-width: 768px) {
        .custom-image {
          max-width: 100% !important;
        }
      }
    `}
                                </style>
                            </div>
                            {/* Service Section   */}
                            <div className="services-section text-center ">
                                <div className="row ">
                                    <div className="col-12">
                                        <div
                                            className="section-title  animate__animated animate__fadeInUp animate__slower">
                                            {/*<p className="common-desctiption">Services i offer to my clients</p>*/}
                                            <h1 className="common-title">About <span>Course</span></h1>
                                            <div className="animated-bar"></div>
                                        </div>
                                        <p className="service-text animate__animated animate__fadeIn animate__delay-1s">
                                            <i>[Course is in process...]</i> <br/>
                                            This course will guide you step-by-step into the <span
                                            className="highlight-skill">Backend development</span> using <span
                                            className="highlight-skill">Java</span>.
                                            You'll learn the basics of the <span className="highlight-skill">Spring Framework</span>,
                                            including IoC, DI, and how to create <span className="highlight-skill">RESTful APIs</span>.
                                            We’ll work with <span
                                            className="highlight-skill">databases</span> using <span
                                            className="highlight-skill">Spring Data JPA</span>, <span
                                            className="highlight-skill">PostgreSQL</span>, and set up database
                                            migrations with Flyway.
                                            <br/>
                                            The course also covers important topics like <span
                                            className="highlight-skill">Spring Security</span>, working with <span
                                            className="highlight-skill">Kafka</span> for processing data asynchronously,
                                            and using <span className="highlight-skill">MapStruct</span> for efficient
                                            data mapping.
                                            You'll learn how to package your applications with <span
                                            className="highlight-skill">Docker</span> and prepare them for deployment
                                            with <span className="highlight-skill">CORS</span>, <span
                                            className="highlight-skill">Nginx</span>, and <span
                                            className="highlight-skill">SSL</span> settings.
                                            <br/>
                                            While the course is being developed, <span className="highlight-skill">subscribe for FREE</span> to
                                            get updates and early access!
                                        </p>


                                    </div>
                                </div>
                                <div
                                    className="row animate__animated animate__zoomIn animate__slower">
                                    {/* Single Item  */}
                                    {servicesData.map((service) => (
                                        <div key={service.id}
                                             className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-1s">
                                            <div className="service-item">
                                                <i className={service.icon}></i>
                                                <h4><span className="service-line">{service.title}</span></h4>
                                                <p>{service.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* End Single Item  */}
                                </div>
                            </div>
                            {/* / service section  */}
                            {/* Testimonials  */}
                            <div className="row">
                                <div className="col-12 animate__animated animate__fadeInDown animate__delay-1s">
                                    <div className="section-title">
                                        <p className="common-desctiption">what my students say</p>
                                        <h1 className="common-title">My <span>Feedback</span></h1>
                                        <div className="animated-bar"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <OwlCarousel className="owl-theme" id="testimonial" {...options}>
                                    {testimonialsData.map((testimonial) => (
                                        <div key={testimonial.id} className="item">
                                            <div className="testimonial-item">
                                                <div className="quote">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <div className="testimonial-img">
                                                    <img src={testimonial.image} alt={`testimonial-${testimonial.id}`}/>
                                                </div>
                                                {/* Имя комментатора */}
                                                <h5 className="highlight-skill">{testimonial.name}</h5>
                                                {/* Текст комментария */}
                                                <p dangerouslySetInnerHTML={{__html: testimonial.text}}/>
                                                <div className="rating">
                                                    {Array.from({length: testimonial.rating}, (_, index) => (
                                                        <i key={index} className="fas fa-3x fa-star"></i>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                            {/* End Testimonials section  */}
                            {/* Fun Fact Section  */}
                            <div className="funfacts-section">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="section-title">
                                            <p className="common-desctiption">Here's what we've achieved</p>
                                            <h1 className="common-title">course <span>stats</span></h1>
                                            <div className="animated-bar"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                        <div className="funfacts-box">
                                            <FunFactsBox endValue={1000}/>
                                            <p className="fun-text">My <span>students</span></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                        <div className="funfacts-box">
                                            <FunFactsBox endValue={25}/>
                                            <p className="fun-text">positive <span>feedback</span></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                        <div className="funfacts-box">
                                            <FunFactsBox endValue={3} suffix="h"/>
                                            <p className="fun-text">Video Lessons <span>Duration</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Section End  */}
        </React.Fragment>
    )
}


export default SpringBoot;
