import React, { useEffect } from 'react';
import mixitup from 'mixitup';
import $ from 'jquery';
import '@fancyapps/fancybox';
import Item1 from '../../images/protfolio/item-1.png'
import Item2 from '../../images/protfolio/item-2.png'
import Item3 from '../../images/protfolio/item-3.png'
import Item4 from '../../images/protfolio/item-4.png'
import Item5 from '../../images/protfolio/item-5.png'
import Item6 from '../../images/protfolio/item-8.png'
import Item7 from '../../images/protfolio/item-7.png'
import Item8 from '../../images/protfolio/item-6.png';
import { Helmet } from 'react-helmet';

const Projects = () => {

  useEffect(() => {
    // Initialize MixItUp
    const mixer = mixitup('.mix-container');

    // Initialize FancyBox after MixItUp has been initialized
    $('.mix-container').on('click', '[data-fancybox="gallery"]', function () {
      $.fancybox.open({
        src: $(this).attr('href'),
        type: 'image',
        opts: {
          animationEffect: 'zoom-in-out',
          transitionEffect: 'tube',
          loop: true,
        },
      });
      return false; // Prevent default click behavior
    });

    // Clean up on component unmount
    return () => {
      mixer.destroy();
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        {/* SEO Page title tag */}
        <title>Mykola Shchypailo | My projects</title>
        {/* Meta description */}
        <meta name="description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />;
      </Helmet>

      {/* Project Section Start*/}
      <section id="portfolio" className="section active">
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__fadeInLeft">
          <div className="container">
            <div className="protfolio-section text-center  ">
              {/* Project-Title Start */}
              <div className="row">
                <div className="col-12">
                  <div className="section-title animate__animated animate__fadeInDown">
                    <p className="common-desctiption">Check out my projects and live websites</p>
                    <h1 className="common-title">My <span>GitHub Repositories</span> and <span>Live Demos</span></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
              {/* Project-Title Start */}
              {/* Project nav-button start  */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-menu animate__animated animate__fadeInUp">
                    <nav className="controls ">
                      <button type="button" className="control clickbtn" data-filter="all">All</button>
                      <button type="button" className="control clickbtn" data-filter=".webdevelopment ">Web Development</button>
                      <button type="button" className="control clickbtn" data-filter=".backend ">Backend</button>
                      <button type="button" className="control clickbtn" data-filter=".testing ">AQA testing</button>
                      <button type="button" className="control clickbtn" data-filter=".mobile ">Mobile</button>
                      <button type="button" className="control clickbtn" data-filter=".api ">API</button>
                    </nav>
                  </div>
                </div>
              </div>
              {/* Projects nav-button End  */}
              {/* Projects Mix Content Start */}
              <div className="row portfolio-list animate__zoomIn animate__animated">
                <div className="container">
                  <ul className="row ps-0 mix-container">
                    {/* Mix Content-Box */}
                    <li className="mix mobile testing col-xl-3 col-lg-4 col-md-6">
                      <a href="https://gitlab.com/nickolaynick87/udemy-joom-project" target="_blank" rel="noopener noreferrer">
                        <img src={Item1} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">Mobile</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content-Box */}
                    <li className="mix api testing col-xl-3 col-lg-4 col-md-6">
                      <a href="https://gitlab.com/nickolaynick87/dummyapi-test-project" target="_blank" rel="noopener noreferrer">
                        <img src={Item2} alt="Image-description"/>
                        <div className="info">
                          <h3 className="title">API</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                    <li className="mix backend col-xl-3 col-lg-4 col-md-6">
                      <a href="https://www.testing87.online/" target="_blank" rel="noopener noreferrer">
                        <img src={Item3} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">Backend</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                    <li className="mix testing col-xl-3 col-lg-4 col-md-6">
                      <a href="https://dev.testing87.online/" target="_blank" rel="noopener noreferrer">
                        <img src={Item4} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">AQA Testing</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                    <li className="mix webdevelopment col-xl-3 col-lg-4 col-md-6">
                      <a href="https://github.com/nick8787/post-wave-demo-project" target="_blank" rel="noopener noreferrer">
                        <img src={Item5} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">Web Development</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                    <li className="mix webdevelopment col-xl-3 col-lg-4 col-md-6">
                      <a href="https://github.com/nick8787/Java-Selenide-Project" target="_blank" rel="noopener noreferrer">
                        <img src={Item6} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">Web Development</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                    <li className="mix api backend col-xl-3 col-lg-4 col-md-6">
                      <a href="https://github.com/nick8787/iam-service-jwt-spring-project" target="_blank" rel="noopener noreferrer">
                        <img src={Item7} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">Backend</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                    <li className="mix api backend col-xl-3 col-lg-4 col-md-6">
                      <a href="https://github.com/nick8787/post-wave-documentation" target="_blank" rel="noopener noreferrer">
                        <img src={Item8} alt="Image-description" />
                        <div className="info">
                          <h3 className="title">Backend</h3>
                          <span className="post">project</span>
                        </div>
                      </a>
                    </li>
                    {/* Mix Content Box */}
                  </ul>
                </div>
              </div>
              {/* Projects Mix Content End */}
            </div>
          </div>
        </div>
      </section>
      {/* Projects Section End */}
    </React.Fragment>
  )
}

export default Projects