import React, { useState } from "react";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Blog1 from "../../../images/blog/mobile-app.png";
import FunFactsBox from "../FunFactsBox";
import FeedbackEmpty from '../../../images/profile/photo-feedback-empty.png'
import Feedback4 from '../../../images/profile/photo-feedback2.jpg'
import Feedback1 from '../../../images/profile/feedback-rz.jpg'

const MobileApp = () => {
    const [options] = useState({
        loop: true,
        margin: 20,
        dots: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 3 },
        },
    });
    // Single Item Data
    const servicesData = [
        {
            id: 1,
            icon: 'fas fa-mobile-alt',
            title: 'Appium Setup',
            description: 'Learn to set up Appium, configure emulators, and connect with Appium Inspector for testing mobile apps.',
        },
        {
            id: 2,
            icon: 'fas fa-laptop-code',
            title: 'Test Project Creation',
            description: 'Build a test automation project from scratch using Java, following best practices and design patterns like PageObject.',
        },
        {
            id: 3,
            icon: 'fas fa-code',
            title: 'Master Appium Technique',
            description: 'Advanced techniques like custom methods, scrolling, and clicks by coordinates.',
        },
        {
            id: 4,
            icon: 'fas fa-layer-group',
            title: 'Java Principles',
            description: 'Understand key Java concepts like abstraction, inheritance, encapsulation, and polymorphism for robust test design.',
        },
        {
            id: 5,
            icon: 'fas fa-cogs',
            title: 'Framework Integration',
            description: 'Use frameworks like JUnit, Selenium, and Lombok to enhance your test automation workflow.',
        },
        {
            id: 6,
            icon: 'fas fa-cloud-upload-alt',
            title: 'CI/CD Pipelines',
            description: 'Set up CI/CD pipelines in GitLab for automated test execution, remote device testing, and report generation.',
        },
        {
            id: 7,
            icon: 'fas fa-video',
            title: 'Reporting & Analytics',
            description: 'Generate detailed test reports, including screenshots and video recordings, for better insights.',
        },
        {
            id: 8,
            icon: 'fas fa-rocket',
            title: 'Scheduled Test Runs',
            description: 'Automate and schedule test runs using suites and pipelines for efficient and repeatable testing.',
        },
    ];

    // Testimonials Data
    const testimonialsData = [
        {
            id: 1,
            image: Feedback1,
            name: 'Viktor',
            text: 'Интересно, полезно, кратко и практично. Хотелось бы больше отсылок на теорию и возможно больше вводных формата "что это, для чего, почему"',
            rating: 5,
        },
        {
            id: 2,
            image: FeedbackEmpty,
            name: 'Yuriy F.',
            text: 'Без затягувань, чітко по суті, послідовно і зрозуміло, як починати і що за чим запускати.',
            rating: 5,
        },
        {
            id: 3,
            image: FeedbackEmpty,
            name: 'Yevhenii T.',
            text: 'Годний курс - все по ділу і динамічно) <br/> було б круто ще покрити iOS частину.',
            rating: 5,
        },
        {
            id: 4,
            image: Feedback4,
            name: 'Саша',
            text: 'Спасибо за подробные и информативные видео',
            rating: 5,
        },
        {
            id: 5,
            image: FeedbackEmpty,
            name: 'Ірина',
            text: 'Good course',
            rating: 4,
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                {/* SEO Page title tag */}
                <title>Mykola Shchypailo | Appium - Mobile App Automation</title>
                {/* Meta description */}
                <meta name="description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />;

                {/* Twitter card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content="https://avstechnolabs.com/og-images/seo-banner.png" />
                <meta name="twitter:title" content="Mykola Shchypailo | Programming courses" />
                <meta name="twitter:creator" content="@mavi" />
                <meta name="twitter:site" content="@mavi" />
                <meta name="twitter:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />

                {/* Facebook card meta tags */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://nick8787.github.io/m-shchypailo" />
                <meta property="og:title" content="Mykola Shchypailo | Programming courses"
                />
                <meta property="og:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
                />
                <meta property="og:image" content="https://avstechnolabs.com/og-images/seo-banner.png"
                />
            </Helmet>
            {/* About Section Start  */}
            <section id="about" className="section active">
                <div className="homecolor-box"></div>
                <div className="common_bg animate__animated animate__fadeInDown">
                    <div className="container">
                        <div className="about-content">
                            {/* About Title Start */}
                            <div className="row ">
                                <div className="col-12 ">
                                    <div
                                        className="section-title animate__animated animate__fadeInDown">
                                        <p className="common-desctiption">My Course</p>
                                        <h1 className="common-title">Appium - Mobile App <span>Automation</span></h1>
                                        <div className="animated-bar"></div>
                                    </div>
                                </div>
                            </div>
                            {/* About Title End  */}
                            {/* Изображение */}
                            <div
                                className="col-12 text-center animate__animated animate__fadeInUp"
                            >
                                <img
                                    src={Blog1}
                                    alt="Spring Boot"
                                    className="img-fluid custom-image"
                                    style={{
                                        maxWidth: "60%",
                                        height: "auto",
                                        marginBottom: "30px",
                                        boxShadow: "0 15px 30px rgba(0, 0, 0, 0.5)",
                                        borderRadius: "8px",
                                    }}
                                />
                                <style>
                                    {`
      @media (max-width: 768px) {
        .custom-image {
          max-width: 100% !important; 
        }
      }
    `}
                                </style>
                            </div>
                            {/* Service Section   */}
                            <div className="services-section text-center ">
                                <div className="row ">
                                    <div className="col-12">
                                        <div
                                            className="section-title  animate__animated animate__fadeInUp animate__slower">
                                            {/*<p className="common-desctiption">Services i offer to my clients</p>*/}
                                            <h1 className="common-title">About <span>Course</span></h1>
                                            <div className="animated-bar"></div>
                                        </div>
                                        <p className="service-text animate__animated animate__fadeIn">
                                            This course will teach you <span className="highlight-skill">Mobile App Automation</span> using <span
                                            className="highlight-skill">Appium</span> and <span
                                            className="highlight-skill">Java</span>.
                                            You'll learn how to set up <span
                                            className="highlight-skill">Android Studio</span>, configure <span
                                            className="highlight-skill">Appium Inspector</span>, and create test
                                            projects from scratch.
                                            <br/>
                                            The course covers key Java principles like <span
                                            className="highlight-skill">abstraction</span>, <span
                                            className="highlight-skill">inheritance</span>, and <span
                                            className="highlight-skill">polymorphism</span>, as well as popular design
                                            patterns like <span className="highlight-skill">PageObject</span>.
                                            <br/>
                                            You’ll also set up <span
                                            className="highlight-skill">CI/CD pipelines</span> on <span
                                            className="highlight-skill">GitLab</span>, run tests on remote devices, and
                                            generate reports with videos and screenshots.
                                        </p>


                                    </div>
                                </div>
                                <div
                                    className="row animate__animated animate__zoomIn animate__slower">
                                    {/* Single Item  */}
                                    {servicesData.map((service) => (
                                        <div key={service.id}
                                             className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-1s">
                                            <div className="service-item">
                                                <i className={service.icon}></i>
                                                <h4><span className="service-line">{service.title}</span></h4>
                                                <p>{service.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* End Single Item  */}
                                </div>
                            </div>
                            {/* / service section  */}
                            {/* Testimonials  */}
                            <div className="row">
                                <div className="col-12 animate__animated animate__fadeInDown animate__delay-1s">
                                    <div className="section-title">
                                        <p className="common-desctiption">what my students say</p>
                                        <h1 className="common-title">My <span>Feedback</span></h1>
                                        <div className="animated-bar"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <OwlCarousel className="owl-theme" id="testimonial" {...options}>
                                    {testimonialsData.map((testimonial) => (
                                        <div key={testimonial.id} className="item">
                                            <div className="testimonial-item">
                                                <div className="quote">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <div className="testimonial-img">
                                                    <img src={testimonial.image} alt={`testimonial-${testimonial.id}`}/>
                                                </div>
                                                {/* Имя комментатора */}
                                                <h5 className="highlight-skill">{testimonial.name}</h5>
                                                {/* Текст комментария */}
                                                <p dangerouslySetInnerHTML={{__html: testimonial.text}}/>
                                                <div className="rating">
                                                    {Array.from({length: testimonial.rating}, (_, index) => (
                                                        <i key={index} className="fas fa-3x fa-star"></i>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                            {/* End Testimonials section  */}
                            {/* Fun Fact Section  */}
                            <div className="funfacts-section">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="section-title">
                                            <p className="common-desctiption">Here's what we've achieved</p>
                                            <h1 className="common-title">course <span>stats</span></h1>
                                            <div className="animated-bar"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                        <div className="funfacts-box">
                                            <FunFactsBox endValue={500}/>
                                            <p className="fun-text">My <span>students</span></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                        <div className="funfacts-box">
                                            <FunFactsBox endValue={25}/>
                                            <p className="fun-text">positive <span>feedback</span></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                        <div className="funfacts-box">
                                            <FunFactsBox endValue={3} suffix="h"/>
                                            <p className="fun-text">Video Lessons <span>Duration</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Section End  */}
        </React.Fragment>
    )
}


export default MobileApp;
