import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            menu: {
                home: "Home",
                blog: "My Courses",
                portfolio: "My Projects",
                about: "About Me",
                contact: "Contact",
            },
            meta: {
                title: "Mykola Shchypailo | Programming courses",
                description: "Learn programming with Mykola Shchypailo: courses, tips, and resources for developers.",
            },
            home: {
                greeting: "Get to know me",
                name: "Mykola Shchypailo",
                role: {
                    backend: "Backend Developer",
                    web: "Web Developer",
                    software: "Software Engineer",
                },
                description: `
          I am an experienced web and backend developer specializing in 
          <span class="highlight-skill">Java</span>, 
          <span class="highlight-skill">Spring Boot</span>, 
          <span class="highlight-skill">Security</span>, 
          <span class="highlight-skill">Rest API</span>,
          <span class="highlight-skill">MySQL</span>,
          <span class="highlight-skill">React</span>, and 
          <span class="highlight-skill">JavaScript</span>. 
          With expertise in modern frameworks and tools like 
          <span class="highlight-skill">Android Studio</span>,
          <span class="highlight-skill">Xcode</span>,
          <span class="highlight-skill">Appium</span>,
          <span class="highlight-skill">Node.js</span>, 
          <span class="highlight-skill">Docker</span>, 
          <span class="highlight-skill">Kafka</span>, and 
          <span class="highlight-skill">PostgreSQL</span>. 
          <br />My focus is on delivering high-quality results and ensuring each project achieves its goals effectively. 
          I build efficient and scalable solutions tailored to client needs.
        `,
                buttons: {
                    hireMe: "My courses",
                    aboutMe: "About me",
                },
            },
            switcher: {
                choose_language: "Choose Language",
                theme: "Choose Theme",
                colors: "Choose Colors",
            },
        },
    },
    ru: {
        translation: {
            menu: {
                home: "Главная",
                blog: "Мои курсы",
                portfolio: "Мои проекты",
                about: "Обо мне",
                contact: "Контакты",
            },
            meta: {
                title: "Николай Щипайло | Курсы программирования",
                description: "Изучайте программирование с Николаем Щипайло: курсы, советы и ресурсы для разработчиков.",
            },
            home: {
                greeting: "Познакомьтесь со мной",
                name: "Николай Щипайло",
                role: {
                    backend: "Backend разработчик",
                    web: "Web разработчик",
                    software: "Программист",
                },
                description: `
          Я опытный web и backend-разработчик, специализирующийся на 
          <span class="highlight-skill">Java</span>, 
          <span class="highlight-skill">Spring Boot</span>, 
          <span class="highlight-skill">Security</span>, 
          <span class="highlight-skill">Rest API</span>,
          <span class="highlight-skill">MySQL</span>,
          <span class="highlight-skill">React</span>, and 
          <span class="highlight-skill">JavaScript</span>. 
          С опытом работы с современными фреймворками и инструментами, такими как 
          <span class="highlight-skill">Android Studio</span>,
          <span class="highlight-skill">Xcode</span>,
          <span class="highlight-skill">Appium</span>,
          <span class="highlight-skill">Node.js</span>,
          <span class="highlight-skill">Docker</span>, 
          <span class="highlight-skill">Kafka</span>, and 
          <span class="highlight-skill">PostgreSQL</span>. 
          <br />Я сосредоточен на достижении высоких результатов и обеспечении успеха каждого проекта. 
          Создаю эффективные и масштабируемые решения, адаптированные под потребности клиента.
        `,
                buttons: {
                    hireMe: "Мои курсы",
                    aboutMe: "Обо мне",
                },
            },
            switcher: {
                choose_language: "Выберите язык",
                theme: "Выберите тему",
                colors: "Выберите цвет",
            },
        },
    },
    ua: {
        translation: {
            menu: {
                home: "Головна",
                blog: "Мої курси",
                portfolio: "Мої проекти",
                about: "Про мене",
                contact: "Контакти",
            },
            meta: {
                title: "Микола Щипайло | Курси програмування",
                description: "Вивчайте програмування з Миколою Щипайло: курси, поради та ресурси для розробників.",
            },
            home: {
                greeting: "Дізнайтеся більше про мене",
                name: "Микола Щипайло",
                role: {
                    backend: "Backend розробник",
                    web: "Web розробник",
                    software: "Програміст",
                },
                description: `
          Я досвідчений web і backend-розробник, що спеціалізується на 
          <span class="highlight-skill">Java</span>, 
          <span class="highlight-skill">Spring Boot</span>, 
          <span class="highlight-skill">Security</span>, 
          <span class="highlight-skill">Rest API</span>,
          <span class="highlight-skill">MySQL</span>,
          <span class="highlight-skill">React</span>, and 
          <span class="highlight-skill">JavaScript</span>.  
          Маю досвід роботи з сучасними фреймворками і інструментами, такими як 
          <span class="highlight-skill">Android Studio</span>,
          <span class="highlight-skill">Xcode</span>,
          <span class="highlight-skill">Appium</span>,
          <span class="highlight-skill">Node.js</span>,
          <span class="highlight-skill">Docker</span>, 
          <span class="highlight-skill">Kafka</span>, and 
          <span class="highlight-skill">PostgreSQL</span>. 
          <br />Я зосереджений на досягненні високих результатів і успіху кожного проєкту. 
          Створюю ефективні і масштабовані рішення, адаптовані до потреб клієнтів.
        `,
                buttons: {
                    hireMe: "Мої курси",
                    aboutMe: "Про мене",
                },
            },
            switcher: {
                choose_language: "Обрати мову",
                theme: "Обрати тему",
                colors: "Обрати кольори",
            },
        },
    },
};

// Определяем язык по умолчанию
const defaultLanguage = localStorage.getItem("language") || "en";

// Инициализация i18next
i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage, // Устанавливаем язык по умолчанию
    fallbackLng: "en", // Резервный язык
    interpolation: {
        escapeValue: false, // Отключаем экранирование, т.к. React защищает от XSS
    },
    detection: {
        order: ["localStorage", "navigator"], // Порядок определения языка
        caches: ["localStorage"], // Кешируем язык в localStorage
    },
});

// Следим за изменением языка и обновляем localStorage
i18n.on("languageChanged", (lang) => {
    localStorage.setItem("language", lang);
});

export default i18n;
