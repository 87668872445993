import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MouseContext } from "../../context/mouseContext";
import Orange from "../../../images/colors/orange.png";
import Purple from "../../../images/colors/purple.png";
import Red from "../../../images/colors/red.png";
import Violet from "../../../images/colors/violet.png";
import Blue from "../../../images/colors/blue.png";
import Golden from "../../../images/colors/golden.png";
import Magenta from "../../../images/colors/magenta.png";
import YellowGreen from "../../../images/colors/yellowgreen.png";
import Green from "../../../images/colors/green.png";
import Yellow from "../../../images/colors/yellow.png";

const SwitchWrapper = ({ template }) => {
  const { i18n, t } = useTranslation();
  const [color, setColor] = useState(localStorage.getItem("colors") || "purple");
  const [themeColor, setTheme] = useState(localStorage.getItem("themeColor") || "dark");
  const [language, setLanguage] = useState(localStorage.getItem("language") || "en");
  const [isOpen, setIsOpen] = useState(false);
  const { cursorChangeHandler } = useContext(MouseContext);

  useEffect(() => {
    template(themeColor, color, language); // Обновляем внешний вид приложения
    i18n.changeLanguage(language); // Меняем язык в i18n
  }, [themeColor, color, language]);

  // Смена темы
  const switchTheme = () => {
    const newTheme = themeColor === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("themeColor", newTheme);
  };

  // Смена языка
  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  // Переключение бокового меню
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
      <React.Fragment>
        {/* Боковая панель стилей */}
        <div className={`style-switch-wrapper ${isOpen ? "active" : ""}`}>
          {/* Кнопка открытия/закрытия панели */}
          <div
              className="style-switch-button"
              onClick={toggleSidebar}
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
          >
            {isOpen ? <i className="fas fa-times"></i> : <i className="fa fa-cog" aria-hidden="true"></i>}
          </div>

          {/* Выбор цветов */}
          <h4>{t("choose colors")}</h4>
          <ul>
            {[
              { color: "orange", src: Orange },
              { color: "blue", src: Blue },
              { color: "red", src: Red },
              { color: "violet", src: Violet },
              { color: "purple", src: Purple },
              { color: "golden", src: Golden },
              { color: "magenta", src: Magenta },
              { color: "yellowgreen", src: YellowGreen },
              { color: "green", src: Green },
              { color: "yellow", src: Yellow },
            ].map(({ color, src }) => (
                <li
                    key={color}
                    onClick={() => {
                      setColor(color);
                      localStorage.setItem("colors", color);
                    }}
                    onMouseEnter={() => cursorChangeHandler("hovered")}
                    onMouseLeave={() => cursorChangeHandler("")}
                >
                  <img src={src} alt={color} />
                </li>
            ))}
          </ul>

          {/* Переключатель темы */}
          <h4>{t("choose theme")}</h4>
          <div
              className="switch"
              onClick={switchTheme}
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
          >
            {themeColor === "light" ? <i className="fas fa-sun"></i> : <i className="fas fa-moon"></i>}
          </div>

          {/* Переключатель языка */}
          <h4>{t("choose language")}</h4>
          <div className="language-switch">
            {["en", "ru", "ua"].map((lang) => (
                <button
                    key={lang}
                    className={language === lang ? "active" : ""}
                    onClick={() => changeLanguage(lang)}
                    onMouseEnter={() => cursorChangeHandler("hovered")}
                    onMouseLeave={() => cursorChangeHandler("")}
                >
                  {lang.toUpperCase()}
                </button>
            ))}
          </div>
        </div>

        {/* Полноэкранное затемнение */}
        <div
            className={`sidebar-overlay ${isOpen ? "active" : ""}`}
            onClick={toggleSidebar}
        ></div>
      </React.Fragment>
  );
};

export default SwitchWrapper;
