import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Gaspar from "../src/mavi/gaspar";
import Cursor from "./mavi/components/common/cursor";
import SwitchWrapper from "./mavi/components/common/switchWrapper";
import MouseContextProvider from "./mavi/context/mouseContext";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { WOW } from "wowjs";
import "./index.css";

function App() {
  const [theme, setTheme] = useState("dark-theme");
  const [color, setColor] = useState("purple");
  const [cursor, setCursor] = useState("default-cursor");
  const [loading, setLoading] = useState(true);

  const wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 60,
    mobile: false,
    live: true,
  });

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Симуляция загрузки
    wow.init(); // Инициализация анимации
  }, []);

  const updateTemplate = (newTheme, newColor, newCursor) => {
    setTheme(newTheme);
    setColor(newColor);
    setCursor(newCursor);
  };

  return (
      <React.Fragment>
        <MouseContextProvider>
          <BrowserRouter basename="/">
            <div className={`${theme} ${color} ${cursor}`}>
              <Cursor />
              {loading ? (
                  <div id="preloader">
                    <div className="loader_line"></div>
                  </div>
              ) : (
                  <React.Fragment>
                    <SwitchWrapper template={updateTemplate} />
                    <Gaspar />
                  </React.Fragment>
              )}
            </div>
          </BrowserRouter>
        </MouseContextProvider>
      </React.Fragment>
  );
}

export default App;
