import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { MouseContext } from "../context/mouseContext";
import { Helmet } from 'react-helmet';

const Contact = () => {
  const { cursorChangeHandler } = useContext(MouseContext);

  const currentYear = new Date().getFullYear();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
    setTimeout(() => setShowModal(false), 3000);
  };

  return (
      <React.Fragment>
        <Helmet>
          <title>Mykola Shchypailo | Contacts</title>
          <meta
              name="description"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
              name="twitter:image"
              content="https://avstechnolabs.com/og-images/seo-banner.png"
          />
          <meta
              name="twitter:title"
              content="Contact Mykola - Personal Portfolio React Template"
          />
          <meta name="twitter:creator" content="@ContactMavi" />
          <meta
              name="twitter:description"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://mavi.avstechnolabs.com/contact" />
          <meta
              property="og:title"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
          />
          <meta
              property="og:description"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
          />
          <meta
              property="og:image"
              content="https://avstechnolabs.com/og-images/seo-banner.png"
          />
        </Helmet>
        <section id="contact" className="section active">
          <div className="homecolor-box"></div>
          <div className="common_bg animate__animated animate__zoomIn">
            <div className="container">
              <div className="contact-section">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title animate__animated animate__fadeInDown">
                      <p className="common-desctiption">
                        Feel Free To Contact Me Anytime
                      </p>
                      <h1 className="common-title">
                        my <span>contact</span>
                      </h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7 col-12">
                    <form
                        className="contact-form animate__animated animate__fadeInLeft"
                        id="contact-form"
                        onSubmit={handleSubmit}
                    >
                      <h4 className="content-title">Contact Me</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 form-group">
                          <input
                              className="form-control"
                              id="contact-name"
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                          <input
                              className="form-control"
                              id="contact-email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 form-group">
                          <input
                              className="form-control"
                              id="contact-subject"
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              value={formData.subject}
                              onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 form-group form-message">
                        <textarea
                            className="form-control"
                            id="contact-message"
                            name="message"
                            placeholder="Message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                        </div>
                        <div className="col-12 mb-4 form-submit" style={{position: 'relative'}}>
                          <button
                              className="clickbtn button-main button-scheme"
                              id="contact-submit"
                              type="button"
                              onClick={handleSubmit}
                          >
                            Send Message
                          </button>
                          {showModal && (
                              <div
                                  className="modal-inline"
                                  style={{
                                    position: 'absolute',
                                    top: '-50px', // Adjust vertical position here
                                    left: '50%', // Adjust horizontal position here
                                    transform: 'translateX(-50%)',
                                    background: 'rgba(0, 0, 0, 0.9)',
                                    color: 'white',
                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                    padding: '10px 15px',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                                    fontSize: '14px',
                                    zIndex: 1000,
                                    whiteSpace: 'nowrap',
                                  }}
                              >
                                <p>Functionality not implemented yet!</p>
                              </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="contact-info animate__animated animate__fadeInRight">
                      <h4 className="content-title">Contact Info</h4>
                      <p className="info-description">
                        I'm always open to interesting opportunities.
                        <br/> Feel free to contact me!
                      </p>
                      <ul
                          className="list-unstyled list-info"
                          style={{display: 'inline-grid'}}
                      >
                        <li>
                          <div className="media d-flex align-items-center">
                          <span className="info-icon">
                            <i className="fas fa-user-alt"></i>
                          </span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Name</h6>
                              <span className="info-value">Mykola Shchypailo</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media d-flex align-items-center">
                          <span className="info-icon">
                            <i className="fas fa-map-pin"></i>
                          </span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Location</h6>
                              <span className="info-value">Kiev, Ukraine</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media d-flex align-items-center">
                          <span className="info-icon">
                            <i className="fas fa-envelope"></i>
                          </span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Email Me</h6>
                              <span className="info-value">
                              <a
                                  href="mailto:mykola.shchypailo@gmail.com"
                                  onMouseEnter={() => cursorChangeHandler('hovered')}
                                  onMouseLeave={() => cursorChangeHandler('')}
                              >
                                mykola.shchypailo@gmail.com
                              </a>
                            </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="fixed-block d-flex animate__animated animate__jackInTheBox animate__delay-1s">
                      <ul className="list-unstyled social-icons">
                        <li
                            onMouseEnter={() => cursorChangeHandler('hovered')}
                            onMouseLeave={() => cursorChangeHandler('')}
                        >
                          <a
                              href="https://www.youtube.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li
                            onMouseEnter={() => cursorChangeHandler('hovered')}
                            onMouseLeave={() => cursorChangeHandler('')}
                        >
                          <a
                              href="https://www.udemy.com/user/mykola-shchypailo/"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <i className="fas fa-graduation-cap"></i>
                          </a>
                        </li>
                        <li
                            onMouseEnter={() => cursorChangeHandler('hovered')}
                            onMouseLeave={() => cursorChangeHandler('')}
                        >
                          <Link
                              to="https://www.linkedin.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <i className="fab fa-linkedin"></i>
                          </Link>
                        </li>
                        <li
                            onMouseEnter={() => cursorChangeHandler('hovered')}
                            onMouseLeave={() => cursorChangeHandler('')}
                        >
                          <Link
                              to="https://github.com/nick8787"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <i className="fab fa-github-square"></i>
                          </Link>
                        </li>
                        <li
                            onMouseEnter={() => cursorChangeHandler('hovered')}
                            onMouseLeave={() => cursorChangeHandler('')}
                        >
                          <a
                              href="https://www.instagram.com/nick_87.87/"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <i className="fab fa-instagram-square"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="map animate__animated animate__fadeInRight animate__delay-1s">
                      <iframe
                          title="Google Map - Khreshchatyk"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.7970701326774!2d30.518121476271!3d50.44773147947471!2m3!1f0!2f0!3f0!3m2!1i1024!1i768!4f13.1!3m3!1m2!1s0x40d4ce53d046642f%3A0x47e244a3cb8b9d03!2z0JrQsNC80LDQu9GM0YHRjNC60L7QuSDQotC10YHRgdC40Y8g0JrQsNC90LDRgdC60LDRjyDQvdGD0YjQutCw!5e0!3m2!1sen!2sua!4v1698882645076!5m2!1sen!2sua"
                          style={{border: 0}}
                          allowFullScreen=""
                          loading="lazy"
                          width="600"
                          height="450"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center animate__animated animate__fadeInUp animate__delay-1s">
                  <div className="col-lg-6 text-center">
                    <h5 className="footer">
                      Copyright &copy; &#160;{currentYear}
                      <a
                          onMouseEnter={() => cursorChangeHandler('hovered')}
                          onMouseLeave={() => cursorChangeHandler('')}
                      >
                        | Created by <span className="highlight-skill">Mykola Shchypailo</span>
                      </a>
                      &#160;&#160;
                      <i
                          className="fas fa-heart animate__animated animate__pulse animate__faster animate__infinite infinite"
                      ></i>
                      &#160;&#160;All rights reserved.
                    </h5>
                  </div>
                </div>
                <style jsx>{`
                  .modal-inline {
                    position: absolute;
                    top: -40px; /* Moves the modal higher */
                    left: -10px; /* Moves the modal to the left */
                    background: rgba(0, 0, 0, 0.8);
                    color: white;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    padding: 10px 15px;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    font-size: 14px;
                    z-index: 100;
                    white-space: nowrap;
                  }

                  .modal-inline p {
                    margin: 0;
                  }
                `}</style>

              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
  );
};

export default Contact;
