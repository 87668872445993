import React from 'react';
import { Routes, Route } from "react-router-dom";
import Layout from './layout';
import Home from './home/home';
import About from './home/about';
import Projects from './home/projects';
import Courses from './home/courses';
import SpringBoot from './home/courses/springBoot';
import Contact from './home/contact';
import MobileApp from "./home/courses/mobileApp";
import ApiAutomation from "./home/courses/apiAutomation";

const Gaspar = () => {
    return (
        <React.Fragment>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/my-projects" element={<Projects />} />
                    <Route path="/my-courses" element={<Courses />} />
                    <Route path="/spring-boot" element={<SpringBoot />} />
                    <Route path="/mobile-app" element={<MobileApp />} />
                    <Route path="/api-automation" element={<ApiAutomation />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </Layout>
        </React.Fragment>
    );
};

export default Gaspar;